const calculator = require('./calculator');
const runtime = require('offline-plugin/runtime');
const bullcodeShieldWhite = require('../assets/icons/bullcode-logos/bullcode-shield-white.svg');
const bullcodeShield = require('../assets/icons/bullcode-logos/bullcode-shield.svg');

runtime.install({
  onUpdateReady() {
    runtime.applyUpdate();
  },
  onUpdated() {
    window.location.reload();
  },
});

let isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  Smartphone: function () {
    return (window.innerWidth <= 384 && window.innerHeight <= 640);
  },
  Tablet: function () {
    return (navigator.userAgent.match(/Tablet|iPad|iPod/i) && window.innerWidth <= 1280 && window.innerHeight <= 800);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};
if (isMobile.any()) {
  $('.is-desktop').remove();
} else {
  $('.is-mobile').remove();
}
$(document).ready(function () {
  if (isMobile.any() && !navigator.userAgent.match(/Tablet|iPad|iPod/i)) {
    $('body').addClass('mobile');
  }

  $('form').on('submit', onSubmitForm);

  // Show mobile images
  if (isMobile.any() && !navigator.userAgent.match(/Tablet|iPad|iPod/i)) {
    //desktop images should be replaced before image loading starts
    $('.stamm-carousel').html('');
    $('.seuticket-carousel').html('');
    $('.russi-carousel').html('');
    $('.melanocitic-carousel').html('');
    $('.likeme-carousel').html('');
    //--
    const stammMocks = require('../assets/imgs/cases-mocks/stamm/mobile/mocks.jpg');
    const stammMocksBlurred = require('../assets/imgs/cases-mocks/stamm/mobile/mocks-blur.jpg');
    const stammMocks2 = require('../assets/imgs/cases-mocks/stamm/mobile/mocks2.jpg');
    const stammMocks2Blurred = require('../assets/imgs/cases-mocks/stamm/mobile/mocks2-blur.jpg');
    $('.stamm-carousel').append(getCarouselCellImage(stammMocks, stammMocksBlurred, 'Mockups Stamm'));
    $('.stamm-carousel').append(getCarouselCellImage(stammMocks2, stammMocks2Blurred, 'Mockups Stamm'));

    const seuticketMocks = require('../assets/imgs/cases-mocks/seuticket/mobile/mocks.jpg');
    const seuticketMocksBlurred = require('../assets/imgs/cases-mocks/seuticket/mobile/mocks-blur.jpg');
    const seuticketMocks2 = require('../assets/imgs/cases-mocks/seuticket/mobile/mocks2.jpg');
    const seuticketMocks2Blurred = require('../assets/imgs/cases-mocks/seuticket/mobile/mocks2-blur.jpg');
    $('.seuticket-carousel').append(getCarouselCellImage(seuticketMocks, seuticketMocksBlurred, 'Mockups Seu Ticket'));
    $('.seuticket-carousel').append(getCarouselCellImage(seuticketMocks2, seuticketMocks2Blurred, 'Mockups Seu Ticket'));

    const russiMocks = require('../assets/imgs/cases-mocks/russi/mobile/mocks.jpg');
    const russiMocksBlurred = require('../assets/imgs/cases-mocks/russi/mobile/mocks-blur.jpg');
    const russiMocks2 = require('../assets/imgs/cases-mocks/russi/mobile/mocks2.jpg');
    const russiMocks2Blurred = require('../assets/imgs/cases-mocks/russi/mobile/mocks2-blur.jpg');
    $('.russi-carousel').append(getCarouselCellImage(russiMocks, russiMocksBlurred, 'Mockups Russi'));
    $('.russi-carousel').append(getCarouselCellImage(russiMocks2, russiMocks2Blurred, 'Mockups Russi'));

    const melanociticMocks = require('../assets/imgs/cases-mocks/melanocitic/mobile/mocks.jpg');
    const melanociticMocksBlurred = require('../assets/imgs/cases-mocks/melanocitic/mobile/mocks-blur.jpg');
    const melanociticMocks2 = require('../assets/imgs/cases-mocks/melanocitic/mobile/mocks2.jpg');
    const melanociticMocks2Blurred = require('../assets/imgs/cases-mocks/melanocitic/mobile/mocks2-blur.jpg');
    $('.melanocitic-carousel').append(getCarouselCellImage(melanociticMocks, melanociticMocksBlurred, 'Mockups Melanocitic'));
    $('.melanocitic-carousel').append(getCarouselCellImage(melanociticMocks2, melanociticMocks2Blurred, 'Mockups Melanocitic'));

    const likemeMocks = require('../assets/imgs/cases-mocks/likeme/mobile/mocks.jpg');
    const likemeMocksBlurred = require('../assets/imgs/cases-mocks/likeme/mobile/mocks-blur.jpg');
    const likemeMocks2 = require('../assets/imgs/cases-mocks/likeme/mobile/mocks2.jpg');
    const likemeMocks2Blurred = require('../assets/imgs/cases-mocks/likeme/mobile/mocks2-blur.jpg');
    $('.likeme-carousel').append(getCarouselCellImage(likemeMocks, likemeMocksBlurred, 'Mockups Likeme'));
    $('.likeme-carousel').append(getCarouselCellImage(likemeMocks2, likemeMocks2Blurred, 'Mockups Likeme'));
  }

  transformAllImgToSvg();
  menuWatch();
  imageLazyLoad();
  maskPhoneFields();
  setTimeout(() => {
    drawMenu();
  }, 100);
  let dataText = ['%bclogo%', 'Aplicativos', 'Sistemas Web', 'Sistemas Inteligentes', 'Produtos Digitais', 'Consultoria', 'Treinamento'];
  startTextAnimation(0, dataText);
  $('.stamm-carousel').flickity({
    prevNextButtons: false,
    pageDots: isMobile.any(),
    contain: true
  });
  $('.russi-carousel').flickity({
    prevNextButtons: false,
    pageDots: isMobile.any(),
    contain: true
  });
  $('.seuticket-carousel').flickity({
    prevNextButtons: false,
    pageDots: isMobile.any(),
    contain: true
  });
  $('.melanocitic-carousel').flickity({
    prevNextButtons: false,
    pageDots: isMobile.any(),
    contain: true
  });
  $('.likeme-carousel').flickity({
    prevNextButtons: false,
    pageDots: isMobile.any(),
    contain: true
  });
  servicesParallax(); //loads content to prevent scroll bugs
  let url = window.location.href;
  section = url.substring(url.lastIndexOf('/') + 1).replace('#', '');
  if ($(`[navigationUrl='${section}']`).length > 0) {
    anchorTo('#' + $(`[navigationUrl='${section}']`).attr('id'), isMobile.any() ? 20 : 0);
  }
});

function getCarouselCellImage(originalImageUrl, blurredImageUrl, imageDescription) {
  return $(`
    <div class="carousel-cell">
      <img src="${blurredImageUrl}"
          alt="${imageDescription}"
          data-src="${originalImageUrl}">
    </div>
  `);
}

function transformAllImgToSvg() {
  // For each image with an SVG class, execute the following function.
  $("img.svg").each(function () {
    // Perf tip: Cache the image as jQuery object so that we don't use the selector muliple times.
    let $img = jQuery(this);
    // Get all the attributes.
    let attributes = $img.prop("attributes");
    // Get the image's URL.
    let imgURL = $img.attr("src");
    // Fire an AJAX GET request to the URL.
    $.get(imgURL, function (data) {
      // The data you get includes the document type definition, which we don't need.
      // We are only interested in the <svg> tag inside that.
      let $svg = $(data).find('svg');
      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');
      // Loop through original image's attributes and apply on SVG
      $.each(attributes, function () {
        $svg.attr(this.name, this.value);
      });
      // Replace image with new SVG
      $img.replaceWith($svg);
    });
  });
}

function imageLazyLoad() {
  $('[data-src]').each(function (index, element) {
    const $el = $(element);
    let img = new Image();
    const src = element.getAttribute('data-src');
    img.onload = function () {
      if ($el.is('img')) {
        element.src = src;
      } else {
        $el.css('backgroundImage', `url(${src})`);
      }
    }
    img.src = src;
  });
}

$(window).scroll(function () {
  drawMenu();
  if (!isMobile.any()) {
    servicesParallax();
  }
  bullcodeLogoParallax();

  clearTimeout($.data(this, 'scrollTimer'));
  $.data(this, 'scrollTimer', setTimeout(() => changeRoute(), 250));
});

function changeRoute() {
  const sections = $('[navigationUrl]');
  sections.each((index, section) => {
    const id = $(section).attr('id');
    if (id == 'home') {
      replaceState();
      return;
    }
    let sectionTopDist = $(section).offset().top;
    let menuInSectionCalc = sectionTopDist - $(window).scrollTop() - 5;
    if (menuInSectionCalc <= 0) {
      replaceState('#' + id);
    }
  });
}

function maskPhoneFields() {
  const phoneMaskOptions = {
    onKeyPress: function (val) {
      if (val.replace(/\D/g, '').length === 11) {
        $('input[name=phone]').mask('(00) 00000-0000', phoneMaskOptions);
      } else {
        $('input[name=phone]').mask('(00) 0000-00009', phoneMaskOptions);
      }
    }
  }
  $('input[name=phone]').mask('(00) 0000-00009', phoneMaskOptions);
}

$(window).click(function (e) {
  // Hide the menus if visible
  if (!isMobile.any() && $('.js-hamburger').hasClass('active') && ($('body').width() - ($('.js-hamburger').find('+ .menu-box').offset().left * 2)) == 0) {
    menuToggle();
  }
});

// Manage menu toggle
function menuWatch() {
  $('.js-hamburger').on("click", function (e) {
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    $(this).off('click');
    menuToggle();
    setTimeout(() => {
      menuWatch();
    }, 550);
  });
}

function menuToggle() {
  if (!$('.js-hamburger').hasClass('active')) {
    $('.js-hamburger').find('+ .menu-box').css('display', 'block');
    setTimeout(() => {
      $('.js-hamburger').addClass("active");
    }, 50);
    $('.hamburger-box svg path').css('fill', '#fff');
    if (isMobile.any()) {
      $('body').css('overflow', 'hidden');
    }
  } else {
    $('.js-hamburger').removeClass("active");
    drawMenu();
    setTimeout(() => {
      $('.js-hamburger').find('+ .menu-box').css('display', 'none');
    }, 550);
    if (isMobile.any()) {
      $('body').css('overflow', 'initial');
    }
  }
}

let cntrlIsPressed = false;

$(document).keydown(function (e) {
  cntrlIsPressed = e.which == "17" || e.which == "91" || e.which == "224";
});

$(document).keyup(function () {
  cntrlIsPressed = false;
});

// prevent page refresh
$('a').on("click", function () { return false; });

// Manage anchors
$('a').on("click", function () {
  let id = $(this).attr('anchorTo');
  let closeMenu = $(this).attr('closeMenu');

  if (id || closeMenu) {

    if (closeMenu != undefined) {
      $('.js-hamburger').removeClass("active");
    }

    anchorTo('#' + id);
  } else {
    // Redirect if is not an anchor
    let href = $(this).attr('href');
    if (href != '#') {
      if (cntrlIsPressed) {
        window.open(href);
      } else {
        window.location.href = href;
      }
    }
  }
});

function anchorTo(element, topSpace) {
  let space = isMobile.any() ? $('#navbar').height() : 0;
  window.scrollTo({ left: 0, top: $(element).offset().top - space + (topSpace || 0), behavior: 'smooth' });
  replaceState(element);
}

function replaceState(element) {
  if (!element) {
    window.history.replaceState(null, document.title, '/');
    return;
  }
  window.history.replaceState(element, document.title, '/#' + $(element).attr('navigationUrl'));
}

let calculatorBody = $('#calculator > .container');
let questionsDiv = $(`
  <div class="questions">
    <form method="post">
    </form>
  </div>`
);
let questionsForm = questionsDiv.find('form');
let currentQuestion = 0;
$('#startCalcBtn').on("click", function () {
  calculatorBody.find('.text').css('display', 'none');
  $(this).css('display', 'none');

  mountQuestion(currentQuestion);
  changeQuestion();
});

function mountQuestion(index) {
  let question = calculator.questions[index];
  let header = getQuestionHeader(question.title, question.subtitle || '');
  let answers = getQuestionAnswers(question.options, question.selectionType);
  let questionDiv = $(`<div class="question"></div>`);
  questionDiv.append(header);
  questionDiv.append(answers);
  return questionDiv;
}

function changeQuestion() {
  questionsForm.html('');
  if (calculator.questions[currentQuestion]) {
    questionsForm.append(mountQuestion(currentQuestion));
    let buttonsDiv = $('<div class="buttons"></div>');
    questionsForm.append(buttonsDiv);

    if (currentQuestion > 0) {
      buttonsDiv.append(getPreviousButton());
    }

    buttonsDiv.append(getNextButton());
    calculatorBody.append(questionsDiv);
    transformAllImgToSvg();
    watchTooltips();
    return;
  }

  let leadInfo = $(`
    <div class="lead-info">
      <form id="calculator_form">
      </form>
    </div>
  `);

  leadInfo.find('> form').append(`
    <div class="header">
      <div class="title">${calculator.config.resultFormTitle}</div>
      <div class="subtitle">${calculator.config.resultFormSubtitle}</div>
    </div>
    <div class="fields">
      <div>
        <input type="text"
              name="name"
              placeholder="name"
              aria-label="Nome">
        <label>Nome</label>
      </div>
      <div>
        <input type="email"
              name="email"
              placeholder="email"
              aria-label="Email">
        <label>Email</label>
      </div>
      <div>
        <input type="tel"
              name="phone"
              placeholder="phone"
              aria-label="Telefone">
        <label>Telefone</label>
      </div>
      <input type="hidden" name="fromCalc" value="true">
      <input type="text" name="human" value="" aria-label="Sou Humano">
    </div>
    <div class="footer"></div>
  `);


  let button = $(`
    <button type="submit"
    class="btn">
      ${calculator.config.resultFormButtonText}
    </button>
  `).on('click', function () {
    leadInfo.find('> form').submit();
    return false;
  });
  let formMessage = $(`<div id="form_message"></div>`);
  leadInfo.find('> form .footer').append(formMessage);
  leadInfo.find('> form .footer').append(button);

  calculatorBody.append(leadInfo);

  leadInfo.find('> form').on('submit', onSubmitForm);
  maskPhoneFields();
  transformAllImgToSvg();
  watchTooltips();
}

function getQuestionHeader(title, subtitle) {
  let headerDiv = $(`<div class="header"></div>`);
  let titleDiv = $(`<div class="title">${title}</div>`);
  headerDiv.append(titleDiv);
  if (subtitle && subtitle.length > 0) {
    let subtitleDiv = $(`<div class="description">${subtitle}</div>`);
    headerDiv.append(subtitleDiv);
  }
  return headerDiv;
}

function getQuestionAnswers(options, selectionType) {
  let answersDiv = $(`<div class="answers${isMobile.any() ? ' mobile' : ''}"></div>`);
  options.forEach((option, index) => {
    let answer = $(`<div class="answer" title="${option.hint || ''}" rel="tooltip"></div>`).on('click', function () {
      let input = $(this).find('input');
      if (input.is(':radio')) {
        input.prop('checked', true);
        options.forEach(option => {
          option.checked = false;
        });
        option.checked = input.prop('checked');
      } else {
        input.prop('checked', !input.prop('checked'));
        option.checked = input.prop('checked');
      }
    });
    answer.append(getOption(option, index, selectionType));
    let description = $(`<div class="description"><span>${option.text}</span></div>`)
    if (option.hint && option.hint.length > 0 && isMobile.any()) {
      let hint = $(`<div class="hint">${option.hint}</div>`);
      description.append(hint);
    }
    answer.append(description);
    answersDiv.append(answer);
  });
  return answersDiv;
}

function getOption(option, index, selectionType) {
  let customSelect = $(`
    <div class="custom-select">
      <div class="select">
      </div>
    </div>
  `);

  let type = 'radio';
  if (selectionType == calculator.selectionTypeEnum.MULTIPLE) {
    type = 'checkbox';
  }

  let input = $(`
    <input type="${type}"
           id="${type == 'radio' ? '0' : index}"
           name="${type == 'radio' ? '0' : index}"
           value="${option.value}"
           ${option.checked ? 'checked="true"' : ''}>
  `).change(function () {
    option.checked = this.checked;
  });

  let label = $(`
    <label for="${index}">
      <img class="svg ${option.class}" src="${option.image || bullcodeShieldWhite}" srcset="${option.image || bullcodeShieldWhite}"/>
    </label>
  `);

  $(customSelect).find('.select').append(input);
  $(customSelect).find('.select').append(label);
  return customSelect;
}

function watchTooltips() {
  if (isMobile.any()) {
    return;
  }

  let targets = $('[rel~=tooltip]'),
    target = false,
    tooltip = false,
    title = false;

  targets.bind('mouseenter', function () {
    target = $(this);
    tip = target.attr('title');
    tooltip = $('<div id="tooltip"></div>');

    if (!tip || tip == '') {
      return false;
    }

    target.removeAttr('title');
    tooltip.css('opacity', 0)
      .html(tip)
      .appendTo('body');

    let init_tooltip = function () {
      if ($(window).width() < tooltip.outerWidth() * 1.5)
        tooltip.css('max-width', $(window).width() / 2);
      else
        tooltip.css('max-width', 340);

      let pos_left = target.offset().left + (target.outerWidth() / 2) - (tooltip.outerWidth() / 2),
        pos_top = target.offset().top - tooltip.outerHeight() - 20;

      if (pos_left < 0) {
        pos_left = target.offset().left + target.outerWidth() / 2 - 20;
        tooltip.addClass('left');
      }
      else
        tooltip.removeClass('left');

      if (pos_left + tooltip.outerWidth() > $(window).width()) {
        pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
        tooltip.addClass('right');
      }
      else
        tooltip.removeClass('right');

      if (pos_top < 0) {
        let pos_top = target.offset().top + target.outerHeight();
        tooltip.addClass('top');
      }
      else
        tooltip.removeClass('top');

      tooltip.css({ left: pos_left, top: pos_top })
        .animate({ top: '+=10', opacity: 1 }, 50);
    };

    init_tooltip();
    $(window).resize(init_tooltip);

    let remove_tooltip = function () {
      tooltip.animate({ top: '-=10', opacity: 0 }, 50, function () {
        $(this).remove();
      });

      target.attr('title', tip);
    };

    target.bind('mouseleave', remove_tooltip);
    tooltip.bind('click', remove_tooltip);
  });
}

function getNextButton() {
  let nextStepButton = $(`
    <button type="button" class="btn nextStep">
      <div class="next-step-arrow"></div>
    </button>
  `).on('click', function (e) {
    let formValues = calculatorBody.find('form').serializeArray();
    let canSkip = calculator.questions[currentQuestion].selectionType == calculator.selectionTypeEnum.MULTIPLE && calculator.questions[currentQuestion].unit != calculator.unitEnum.BASEDAYS;

    if ((formValues && formValues.length > 0) || canSkip) {
      currentQuestion++;
      changeQuestion();
      anchorTo($('#calculator'));
    } else {
      console.log('Você não pode avançar ainda! Os  campos não foram preenchidos corretamente!');
    }
  });
  return nextStepButton;
}

function getPreviousButton() {
  let previousStepButton = $(`
    <button type="button" class="btn previousStep">
      <div class="previous-step-arrow"></div>
    </button>
  `).on('click', function (e) {
    currentQuestion--;
    changeQuestion();
  });
  return previousStepButton;
}

$('.address > a').on('click', function (e) {
  let cityName = 'Rio do Sul';
  switch ($(e.target).className) {
    case 'bc':
      cityName = 'Balneário Camboriú';
      break;
    case 'itapema':
      cityName = 'Itapema';
      break;
    case 'rsl':
      cityName = 'Rio do Sul';
      break;
    default:
    case 'fln':
      cityName = 'Florianópolis';
      break;
  }

  let message = $('textarea[name=message]');
  message.val(`Olá, \n\nGostaria de agendar uma conversa. \nEstou próximo a ${cityName}.`);
  message.get(0).focus();
  if (isMobile.any()) {
    anchorTo(message, -50);
  }
});

$('#services .steps .step').on(isMobile.any() ? 'click' : 'mouseover', function () {
  const idx = $(this).find('> .index').text();
  $('#services').find('> .container .step').removeClass("active");
  $(this).addClass("active");
  $('#services').find('> .container .text').css('display', 'none');
  $('#services').find('> .container #' + idx).css('display', 'block');
  if (isMobile.any()) {
    anchorTo(this);
  }
});

// Draw Menu based on page position
function drawMenu() {
  // Elements of navbar
  const logo = $('#bc-logo-0');
  const toolbar = $('#navbar');
  const menu = $('.hamburger-box');
  const sections = $('[menuColor]');

  if (isMobile.any() && !isMobile.Tablet()) {
    if ($(window).scrollTop() > 10) {
      $(toolbar).css({
        'transition': 'all 0.4s',
        'background': 'rgba(255, 255, 255, 1)',
        'border-bottom': '2px solid rgb(164, 46, 72)',
        'height': '55px'
      });
      $('#bc-logo-0 svg path').css('fill', 'rgb(164, 46, 72)');
      $('.hamburger-box svg path').css('fill', 'rgb(164, 46, 72)');
    } else {
      $(toolbar).css({
        'background': 'transparent',
        'border': 'none',
        'height': '75px'
      });
      $('#bc-logo-0 svg path').css('fill', '#fff');
      $('.hamburger-box svg path').css('fill', '#fff');
    }
    return;
  }

  // Elements of navbar calc
  let distLogoRelativeScreen = calcMenuItemDist(logo);
  let distMenuRelativeScreen = calcMenuItemDist(menu);
  let navbarBottomDist = toolbar.offset().top + toolbar.height();

  sections.each((index, section) => {
    let sectionTopDist = $(section).offset().top;
    let sectionColor = $(section).attr('menuColor');

    if (navbarBottomDist > sectionTopDist) {
      let logoInSectionCalc = sectionTopDist - $(window).scrollTop() - distLogoRelativeScreen;
      if (logoInSectionCalc <= 0) {
        let drawHeight = Math.min((logoInSectionCalc * -1), $(logo).height());
        $('#bc-logo-1 svg path').css('fill', sectionColor);
        $('#bc-logo-1').css('height', drawHeight);

        if (drawHeight >= $(logo).height()) {
          $('#bc-logo-0 svg path').css('fill', sectionColor);
          $('#bc-logo-1').css('height', 0);
        }
      } else {
        $('#bc-logo-1').css('height', 0);
      }

      if ($('.js-hamburger').hasClass('active')) {
        sectionColor = '#fff';
      }

      let menuInSectionCalc = sectionTopDist - $(window).scrollTop() - distMenuRelativeScreen;
      if (menuInSectionCalc <= 0) {
        let drawHeight = Math.min((menuInSectionCalc * -1), $(menu).height());
        $('.hamburger-box.mask svg path').css('fill', sectionColor);
        $('.hamburger-box.mask').css('height', drawHeight);

        if (drawHeight >= $(menu).height()) {
          $('.hamburger-box svg path').css('fill', sectionColor);
          $('.hamburger-box.mask').css('height', 0);
        }
      } else {
        $('.hamburger-box.mask').css('height', 0);
      }
    }
  });
}

function servicesParallax() {
  let transformStarterPointDistance = 100; //in vh
  let layers = $('#services .services-parallax .layer');
  let texts = $('#services .services-parallax .sticky-container > div').not('.layer');
  texts.eq(0).css('display', 'flex');
  let finalFixedScrollSize = 100;
  let parallaxTopDist = $('#services .services-parallax').css({
    'height': (transformStarterPointDistance * layers.length) - finalFixedScrollSize + 'vh'
  });
  let scrollDist = $(window).scrollTop();
  parallaxTopDist = $('#services .services-parallax').offset() ? $('#services .services-parallax').offset().top || 0 : 0;
  // computes the difference between the parallax block and the page scroll
  let startAnimationPoint = 100; //in px
  let scrollParallaxDif = scrollDist - parallaxTopDist;
  let screenHeight = $(window).innerHeight() * (transformStarterPointDistance / 100);
  if (scrollParallaxDif >= 0) {
    $('#services .services-parallax .sticky-container > div:not(.layer)').css('position', 'fixed');
    $('#services .services-parallax .sticky-container > div:not(.layer)').css('top', '0');
  } else {
    $('#services .services-parallax .sticky-container > div:not(.layer)').css('position', 'relative');
  }
  layers.each(function (index) {
    let animation = screenHeight - (scrollParallaxDif - (screenHeight * Math.max(index - 1))) + startAnimationPoint;
    layers.eq(index).css({
      'transform': 'translate(0px, ' + (index * 100) + 'vh)'
    });
    if (index == texts.length - 1) {
      texts.eq(index).css({
        'transition': 'top 1s'
      });
      if ((animation - finalFixedScrollSize) <= 0) {
        texts.eq(index).css({
          'position': 'absolute',
          'top': 'auto',
          'transform': 'translate(0px, 300vh)'
        });
      } else {
        texts.eq(index).css({
          'position': 'fixed',
          'top': '0',
          'transform': 'translate(0px, 0)',
        });
      }
    }
    if (scrollParallaxDif > ((screenHeight * Math.max(index - 1, 0)) + startAnimationPoint)) {
      texts.eq(index).css('display', 'flex');
    } else if (index != 0) {
      texts.eq(index).css('display', 'none');
    }
  });
}

function bullcodeLogoParallax() {
  let scrollDist = $(window).scrollTop();
  let calculatorTop = $('#calculator').offset().top;
  let diff = scrollDist - calculatorTop;
  if (scrollDist > calculatorTop) {
    $('.bullcode-dark-red-logo').css('bottom', diff * 0.15);
  }
}

// calculates the distance between the bottom of the menu item and the top of the screen
function calcMenuItemDist(menuItem) {
  let navbarDist = $('#navbar').offset().top;
  let menuItemBottomDist = menuItem.offset().top + menuItem.height();
  return menuItemBottomDist - navbarDist;
}

// Start text typing animation 
function startTextAnimation(i, dataText) {
  if (typeof dataText[i] == 'undefined') {
    setTimeout(function () {
      startTextAnimation(0, dataText);
    }, 500);
  }
  if (dataText && dataText[i] && i < dataText[i].length) {
    typeWriter(dataText[i], 0, function () {
      startTextAnimation(i + 1, dataText);
    });
  }
}

// Typing animation
function typeWriter(text, i, fnCallback) {
  if (i < (text.length)) {
    let logoSize = 10;
    if (isMobile.any() && window.innerWidth <= 800) {
      logoSize = 14;
    }
    if (text == '%bclogo%') {
      $('#center-message > .text').html('<span><div class="bullcode-white-logo"></div></span><span aria-hidden="true"></span>');
      switch (i) {
        case 0:
          $('#center-message > .text div.bullcode-white-logo').width('calc((' + logoSize + 'vw * 3.91001) * 0.15)');
          break;
        case 1:
          $('#center-message > .text div.bullcode-white-logo').width('calc((' + logoSize + 'vw * 3.91001) * 0.3175)');
          break;
        case 2:
          $('#center-message > .text div.bullcode-white-logo').width('calc((' + logoSize + 'vw * 3.91001) * 0.39)');
          break;
        case 3:
          $('#center-message > .text div.bullcode-white-logo').width('calc((' + logoSize + 'vw * 3.91001) * 0.4625)');
          break;
        case 4:
          $('#center-message > .text div.bullcode-white-logo').width('calc((' + logoSize + 'vw * 3.91001) * 0.575)');
          break;
        case 5:
          $('#center-message > .text div.bullcode-white-logo').width('calc((' + logoSize + 'vw * 3.91001) * 0.74)');
          break;
        case 6:
          $('#center-message > .text div.bullcode-white-logo').width('calc((' + logoSize + 'vw * 3.91001) * 0.855)');
          break;
        case 7:
          $('#center-message > .text div.bullcode-white-logo').width('calc(' + logoSize + 'vw * 3.91001)');
          break;
      }
    } else {
      $('#center-message > .text').html('<span>' + text.substring(0, i + 1) + '</span><span aria-hidden="true"></span>');
    }

    setTimeout(function () {
      typeWriter(text, i + 1, fnCallback);
    }, 100);
  }
  else if (typeof fnCallback == 'function') {
    setTimeout(fnCallback, 5000);
  }
}

Number.prototype.toCurrency = function () {
  return this.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};

function onSubmitForm(e) {
  try {
    let currentForm = $(this);
    $(currentForm).find('#form_message').html('');
    e.preventDefault();
    let formValues = $(this).serializeArray();
    formValues.get = function (name) {
      if (typeof name == 'string') {
        return formValues.find(obj => obj.name == name);
      }
      return;
    };

    let fieldsValidation = [
      {
        name: "name",
        required: true,
        minLength: 5
      },
      {
        name: "email",
        regex: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        required: true
      },
      {
        name: "phone",
        required: true,
        minLength: 14,
      },
      {
        name: "message",
        required: true,
        minLength: 10
      },
      {
        name: "human",
        required: true,
        maxLength: 0
      },
      {
        name: "fromCalc",
      },
    ];

    // Returns a serializedArray of the field of validation
    fieldsValidation.get = function (name) {
      if (typeof name == 'string') {
        return fieldsValidation.find(obj => obj.name == name);
      }
      return;
    };

    // Assigns the attribute "isTextArea" to the validations
    $(this).find('textarea').each((index, field) => {
      let fieldName = $(field).serializeArray()[0].name;
      fieldsValidation.get(fieldName).isTextArea = true;
    });
    let error = false;

    // Checks if fields are valid
    formValues.forEach((field) => {
      let fieldOfValidation = fieldsValidation.get(field.name);
      if (!fieldOfValidation) {
        // If the field does not exist in the validation array, the form can not be sent
        console.error('Inválid Form');
        error = true;
        return;
      } else if (fieldOfValidation.required) {
        if ((fieldOfValidation.hasOwnProperty('minLength') && field.value.length < fieldOfValidation.minLength) ||
          (fieldOfValidation.hasOwnProperty('maxLength') && field.value.length > fieldOfValidation.maxLength) ||
          (fieldOfValidation.hasOwnProperty('regex') && !field.value.match(fieldOfValidation.regex))) {
          error = true;
          // Add invalid class
          let invalidBackground = $('<div class="invalid_background"></div>');
          if (fieldOfValidation.isTextArea) {
            $(currentForm).find(`textarea[name="${field.name}"]`).addClass('invalid');
            $(currentForm).find(`textarea[name="${field.name}"]`).parent().append(invalidBackground);
          } else {
            $(currentForm).find(`input[name="${field.name}"]`).addClass('invalid');
            $(currentForm).find(`input[name="${field.name}"]`).parent().append(invalidBackground);
          }
          $(currentForm).find('#form_message').html('Ops. Preencha corretamente os campos destacados!');
          $(currentForm).find('button[type="submit"]').addClass('invalid');
          setTimeout(function () {
            $(currentForm).find('button[type="submit"]').removeClass('invalid');
          }, 1000);
        } else {
          // Remove invalid class
          if (fieldOfValidation.isTextArea) {
            $(currentForm).find(`textarea[name="${field.name}"]`).removeClass('invalid');
            $(currentForm).find(`textarea[name="${field.name}"]`).parent().find('.invalid-background').remove();
          } else {
            $(currentForm).find(`input[name="${field.name}"]`).removeClass('invalid');
            $(currentForm).find(`input[name="${field.name}"]`).parent().find('.invalid-background').remove();
          }
          $(currentForm).find('button[type="submit"]').removeClass('invalid');
          $(currentForm).find('button[type="submit"]').addClass('valid');
        }
      }
    });
    if (error) {
      $(currentForm).find('#form_message')
        .fadeOut(250)
        .fadeIn(250);
      return false;
    }

    let data = {};
    formValues.forEach((field) => {
      if (field.name == 'human') {
        return;
      }
      data[field.name] = field.value;
    });
    if (data.hasOwnProperty('fromCalc')) {
      data.value = calculator.getTotal();
      data.answers = JSON.stringify(calculator.getAnswers());
    }
    let submitButton = $(currentForm).find('button[type="submit"]').remove();
    let logo = $(`<img class="svg" src="${bullcodeShield}">`);
    let spanLoading = $(`<span class="loading">Enviando...</span>`);
    let loadingBox = $(`<div class="loading-box"></div>`);
    loadingBox.append(logo);
    loadingBox.append(spanLoading);
    $(currentForm).find('> .footer').append(loadingBox);
    transformAllImgToSvg();
    $(currentForm).find('> .footer > svg path').css('fill', '#fff');
    $.post('https://script.google.com/macros/s/AKfycbypKr44YFmXoHgUKzkChcSmOAqv8-XrzTiK_7YSkl4qie887vVUv_gIBwXq2lYZ0Wni/exec', data)
      .done(function () {
        $(currentForm).find('#form_message').html('Mensagem enviada com sucesso!');
        if (data.fromCalc) {
          createResult();
        }
        $(currentForm).find('> .footer svg, > .footer .loading-box').remove();
      })
      .fail(function () {
        $(currentForm).find('> .footer svg, > .footer .loading-box').remove();
        $(currentForm).find('#form_message').html('Não foi possível enviar sua mensagem, tente novamente.');
        $(currentForm).find('> .footer').append(submitButton);
      });
  } catch (e) {
    return false;
  }
}

function createResult() {
  let calculatorForm = $('#calculator_form');
  calculatorForm.html('');

  let header = $(`<div class="header"><div class="title">Resultado</div></div>`);
  let moneyIcon = $(`<div class="money-icon"></div>`);
  let body = $(`<div class="body"></div>`);
  let row = $(`<div class="row"></div>`);
  let rowResults = $(`<div class="results"></div>`);
  let rowTitle = $(`<div class="title">Valor final</div>`);
  let rowValue = $(`<div class="value">${calculator.getTotal().toCurrency()}</div>`);
  let rowWarning = $(`<div class="warning">os valores são estimativas aproximadas e podem não refletir o valor real</div>`);
  row.append(moneyIcon);
  rowResults.append(rowTitle);
  rowResults.append(rowValue);
  rowResults.append(rowWarning);
  row.append(rowResults);
  body.append(row);
  calculatorForm.append(header);
  calculatorForm.append(body);
  anchorTo('#calculator');
}