/*
interface Question {
  title: String;
  subtitle: String;
  selectionType: 'multiple' | 'single';
  unit: unitEnum.BASEDAYS | unitEnum.DAYS | unitEnum.PERCENTAGE;
  options: Array<QuestionOption>;
}

interface QuestionOption {
  text: String;
  hint?: String;
  value: Number;
  image: String;
  class?: String;
  checked?: Boolean;
}
*/

const config = {
  dayBaseValue: (8 * 100),
  deliveryEstimateMultiplier: 2,
  extraDaysMultiplier: 1.2,
  resultFormTitle: 'Pronto para começar?',
  resultFormSubtitle: 'Nos fale um pouco sobre você.',
  resultFormButtonText: 'Ver Resultado'
};

var selectionTypeEnum = {
  'MULTIPLE': 0,
  'SINGLE': 1
};
Object.freeze(selectionTypeEnum);

var unitEnum = {
  'BASEDAYS': 0,
  'PERCENTAGE': 1,
  'DAYS': 2
};
Object.freeze(unitEnum);

let questions = [
  {
    title: 'Para quais plataformas?',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.BASEDAYS,
    options: [
      {
        text: 'Web',
        hint: 'Aplicativo web e/ou API para fornecimento e controle dos dados consumidos pelo aplicativo móvel.',
        value: 50,
        image: require('../assets/icons/calculator-icons/web.svg'),
      },
      {
        text: 'iOS',
        value: 40,
        image: require('../assets/icons/calculator-icons/ios.svg'),
      },
      {
        text: 'Android',
        value: 40,
        image: require('../assets/icons/calculator-icons/android.svg'),
      },
    ]
  },
  {
    title: 'Qual o tamanho do seu aplicativo?',
    selectionType: selectionTypeEnum.SINGLE,
    unit: unitEnum.PERCENTAGE,
    options: [
      {
        text: 'Pequeno',
        hint: 'Seu app tem até 3 telas principais (excluindo telas de login, cadastro e conteúdo estático).',
        value: 10,
        image: require('../assets/icons/calculator-icons/mobile.svg'),
        class: 'small',
      },
      {
        text: 'Médio',
        hint: 'Seu app tem até 7 telas principais (excluindo telas de login, cadastro e conteúdo estático).',
        value: 25,
        image: require('../assets/icons/calculator-icons/mobile.svg'),
        class: 'medium',
      },
      {
        text: 'Grande',
        hint: 'Seu app tem mais de 8 telas principais (excluindo telas de login, cadastro e conteúdo estático).',
        value: 50,
        image: require('../assets/icons/calculator-icons/mobile.svg'),
      },
    ]
  },
  {
    title: 'Nível de interface desejada',
    selectionType: selectionTypeEnum.SINGLE,
    unit: unitEnum.PERCENTAGE,
    options: [
      {
        text: 'Protótipo',
        hint: 'Simples mas funcional, ideal para validação da ideia e entrada rápida no mercado.',
        value: 10,
        image: require('../assets/icons/calculator-icons/leaf.svg'),
      },
      {
        text: 'Básica',
        hint: 'Simples mas agradável aos olhos.',
        value: 25,
        image: require('../assets/icons/calculator-icons/leaf-box.svg'),
      },
      {
        text: 'Elegante',
        hint: 'Interface moderna e única, desenvolvida sob medida para o seu projeto. Podendo conter animações e transições especiais.',
        value: 50,
        image: require('../assets/icons/calculator-icons/flowers.svg'),
      },
    ]
  },
  {
    title: 'Usuários e contas',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'E-mail e senha',
        hint: 'Autênticação padrão utilizando login e senha.',
        value: 3,
        image: require('../assets/icons/calculator-icons/email.svg'),
      },
      {
        text: 'Login com redes sociais',
        hint: 'Autenticação utilizando contas em redes sociais como Facebook, Twitter e Instagram.',
        value: 3,
        image: require('../assets/icons/calculator-icons/social-networks.svg'),
      },
      {
        text: 'Reconhecimento biométrico',
        hint: 'Impressão digital ou reconhecimento facial',
        value: 5,
        image: require('../assets/icons/calculator-icons/biometric.svg'),
      },
      {
        text: 'Convidar usuários por e-mail',
        hint: 'Novos usuários podem ser convidados através de e-mail convite.',
        value: 2,
        image: require('../assets/icons/calculator-icons/add-email.svg'),
      },
      {
        text: 'Multitenancy',
        hint: 'Possibilita a visualização do sistema assumindo o papel de outro usuário, comum em SaaS (Softwares como Serviço)',
        value: 10,
        image: require('../assets/icons/calculator-icons/multitenancy.svg'),
      },
      {
        text: 'Autenticação de dois fatores',
        hint: 'Comuns em sistemas financeiros ou com dados altamente sensíveis, requer que o usuário forneça informação adicional de confirmação (além da senha).',
        value: 10,
        image: require('../assets/icons/calculator-icons/two-factor-auth.svg'),
      },
    ]
  },
  {
    title: 'Conteúdo de usuário',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'Envio de arquivos',
        hint: 'Usuário poderá enviar arquivos como imagens, PDF, etc.',
        value: 2,
        image: require('../assets/icons/calculator-icons/upload.svg'),
      },
      {
        text: 'E-mails transacionais',
        hint: 'O usuário será automaticamente notificado via e-mail quando houver alguma alteração ou simplesmente para aumentar seu engajamento.',
        value: 3,
        image: require('../assets/icons/calculator-icons/email-plane.svg'),
      },
      {
        text: 'Avaliação ou ranking',
        hint: 'Sistema de avaliação e votação, utilizado para avaliar o nível de satisfação dos clientes com o produto/serviço. Comum em aplicativos de entrega e restaurantes.',
        value: 5,
        image: require('../assets/icons/calculator-icons/medal.svg'),
      },
      {
        text: 'Manipulação de mídia',
        hint: 'Áudios, vídeos e fotos, com aplicação de filtro, por exemplo.',
        value: 20,
        image: require('../assets/icons/calculator-icons/figures.svg'),
      },
    ]
  },
  {
    title: 'Recursos específicos',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'Funcionamento offline',
        hint: 'O aplicativo continuará funcionando mesmo quando não houver conexão com a Internet e sincronizará os dados quando houver.',
        value: 30,
        image: require('../assets/icons/calculator-icons/offline.svg'),
      },
      {
        text: 'Sensores do dispositivo',
        hint: 'Acelerômetro, giroscópio, bússola, sensor de luminosidade, etc.',
        value: 2,
        image: require('../assets/icons/calculator-icons/sensors.svg'),
      },
      {
        text: 'Códigos de barras ou QRCodes',
        hint: 'Leitura ou geração de códigos de barras e/ou QRCodes.',
        value: 1,
        image: require('../assets/icons/calculator-icons/code.svg'),
      },
      {
        text: 'Dados de saúde',
        hint: 'Coletar ou usar dados relacionados à saúde e atividades do usuário.',
        value: 3,
        image: require('../assets/icons/calculator-icons/health.svg'),
      },
      {
        text: 'Apple Watch',
        hint: 'O aplicativo estará disponível também no Apple Watch.',
        value: 50,
        image: require('../assets/icons/calculator-icons/apple-watch.svg'),
      },
    ]
  },
  {
    title: 'Datas e Localizações',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'Calendário',
        hint: 'Exibir e coletar dados em formato de calendário.',
        value: 2,
        image: require('../assets/icons/calculator-icons/calendar.svg'),
      },
      {
        text: 'Mapa ou geolocalização',
        hint: 'Exibir mapa com localizações específicas ou baseado na localização do usuário.',
        value: 2,
        image: require('../assets/icons/calculator-icons/map-mark.svg'),
      },
      {
        text: 'Traçar rota',
        hint: 'Indicar melhor caminho a ser percorrido entre dois pontos.',
        value: 5,
        image: require('../assets/icons/calculator-icons/route.svg'),
      },
      {
        text: 'Agendamento',
        hint: 'Agendamento de eventos, consultas, etc., controlando carga e data e hora de ínicio e fim.',
        value: 3,
        image: require('../assets/icons/calculator-icons/add-date.svg'),
      },
    ]
  },
  {
    title: 'Redes Sociais, Comunicação e Engajamento',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'Chat',
        hint: 'Permite que usuários da aplicação troquem mensagens entre si e/ou em grupos.',
        value: 20,
        image: require('../assets/icons/calculator-icons/chat.svg'),
      },
      {
        text: 'Sistema de fórum ou comentários',
        hint: 'Funcionamento clássico de um fórum ou simples comentários em produtos, postagens, artigos, etc.',
        value: 10,
        image: require('../assets/icons/calculator-icons/comments.svg'),
      },
      {
        text: 'Compartilhar nas redes sociais',
        hint: 'Permite e facilita o compartilhamento de conteúdo nas redes sociais de forma organizada visando gerar tráfego e engajamento.',
        value: 1,
        image: require('../assets/icons/calculator-icons/share.svg'),
      },
      {
        text: 'Notificações Push',
        hint: 'Notifica usuários em tempo real sobre alterações nas informações do app.',
        value: 10,
        image: require('../assets/icons/calculator-icons/push-notification.svg'),
      },
      {
        text: 'Streaming de Vídeo',
        hint: 'Transmissão de vídeo em tempo real.',
        value: 30,
        image: require('../assets/icons/calculator-icons/video-streaming.svg'),
      },
    ]
  },
  {
    title: 'Vendas e Cobrança',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'Processar pagamentos',
        hint: 'Permitirá o processamento de pagamentos e gerenciamento de reembolsos.',
        value: 10,
        image: require('../assets/icons/calculator-icons/payment.svg'),
      },
      {
        text: 'Planos de assinatura',
        hint: 'O aplicativo terá planos de uso que o usuário pagará periodicamente.',
        value: 10,
        image: require('../assets/icons/calculator-icons/subscription.svg'),
      },
      {
        text: 'Catálogo de produtos',
        hint: 'Consiste na estrutura de cadastro, organização e categorização dos produtos a serem exibidos no aplicativo.',
        value: 5,
        image: require('../assets/icons/calculator-icons/catalog.svg'),
      },
      {
        text: 'Carrinho de compras',
        hint: 'Usuários poderão adicionar produtos do catálogo a um carrinho de compras antes de proceder com o pagamento.',
        value: 5,
        image: require('../assets/icons/calculator-icons/shopping-cart.svg'),
      },
      {
        text: 'Marketplace do usuário',
        hint: 'Usuários poderão vender produtos ou serviços para outros usuários através do seu aplicativo. Sistema de comissionamento de vendas.',
        value: 25,
        image: require('../assets/icons/calculator-icons/marketplace.svg'),
      },
      {
        text: 'Compras no aplicativo',
        hint: 'Para liberação de conteúdo ou funcionalidades do próprio aplicativo.',
        value: 5,
        image: require('../assets/icons/calculator-icons/in-app-purchases.svg'),
      },
      {
        text: 'Histórico de compras e pedidos',
        hint: 'Listagem das compras já realizadas no aplicativo.',
        value: 2,
        image: require('../assets/icons/calculator-icons/orders.svg'),
      },
    ]
  },
  {
    title: 'Administração e Relatórios',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'Integração com CMS',
        hint: 'Um sistema de gerenciamento de conteúdo (CMS) permitirá que grande parte dos dados estáticos exibidos no aplicativo sejam alterados sem intervenção de um desenvolvedor.',
        value: 20,
        image: require('../assets/icons/calculator-icons/cms-integration.svg'),
      },
      {
        text: 'Gerenciamento de usuários',
        hint: 'Administradores poderão listar, criar, alterar, remover e bloquear usuários.',
        value: 1,
        image: require('../assets/icons/calculator-icons/users-management.svg'),
      },
      {
        text: 'Moderação e/ou aprovação de conteúdos',
        hint: 'Administradores poderão avaliar e aprovar ou recusar conteúdos provenientes de usuários antes (ou depois) que os mesmos tornem-se públicos.',
        value: 5,
        image: require('../assets/icons/calculator-icons/content-approval.svg'),
      },
      {
        text: 'Estatísticas de uso',
        hint: 'Descubra de onde vêm seus usuários e como se comportam.',
        value: 5,
        image: require('../assets/icons/calculator-icons/statistics.svg'),
      },
      {
        text: 'Disponibilizar em outros idiomas',
        hint: 'Prover textos do aplicativo em outros idiomas além do português brasileiro.',
        value: 10,
        image: require('../assets/icons/calculator-icons/availability-languages.svg'),
      },
    ]
  },
  {
    title: 'Integração com APIs externas',
    selectionType: selectionTypeEnum.MULTIPLE,
    unit: unitEnum.DAYS,
    options: [
      {
        text: 'Conectar a um ou mais serviços de terceiros',
        hint: 'Parte dos dados será consumida de um serviço de terceiros.',
        value: 20,
        image: require('../assets/icons/calculator-icons/third-apps.svg'),
      },
      {
        text: 'Disponibilizar API para terceiros',
        hint: 'Criar interface de programação para que terceiros integrem-se ao seu aplicativo',
        value: 40,
        image: require('../assets/icons/calculator-icons/third-api.svg'),
      },
      {
        text: 'Mensagens via SMS',
        hint: 'Permite que seu aplicativo envie mensagens SMS.',
        value: 15,
        image: require('../assets/icons/calculator-icons/sms-messages.svg'),
      },
      {
        text: 'Mensagens via WhatsApp',
        hint: 'Permite que seu aplicativo envie mensagens pelo mensageiro WhatsApp.',
        value: 15,
        image: require('../assets/icons/calculator-icons/whatsapp-messages.svg'),
      },
    ]
  },
];

function calculateTotalDays() {
  let baseDays = 0;
  let calculatedBaseDays = 0;
  let extraDays = 0;
  questions.sort((a, b) => {
    if (a.unit == b.unit) {
      return 0;
    }
    if (a.unit != unitEnum.BASEDAYS && b.unit == unitEnum.BASEDAYS) {
      return 1;
    } else if (a.unit == unitEnum.BASEDAYS && b.unit != unitEnum.BASEDAYS) {
      return -1;
    }
  });
  questions.forEach(q => {
    q.options.forEach(opt => {
      if (opt.checked) {

        switch (q.unit) {
          case unitEnum.BASEDAYS:
            baseDays += opt.value;
            break;
          case unitEnum.PERCENTAGE:
            calculatedBaseDays += Math.round(((baseDays) * (opt.value / 100)));
            break;
          case unitEnum.DAYS:
            extraDays += opt.value;
            break;
        }
      }
    });
  });
  return Math.floor(calculatedBaseDays + (extraDays * config.extraDaysMultiplier));
}

function getAnswers() {
  let answers = []
  questions.map(q => q.options.map(o => (o.checked) ? answers.push(o) : null));
  return answers;
}

module.exports = {
  selectionTypeEnum: selectionTypeEnum,
  unitEnum: unitEnum,
  config: config,
  questions: questions,
  getAnswers: getAnswers,
  calculateTotalDays: calculateTotalDays,
  getTotal: () => calculateTotalDays() * config.dayBaseValue,
  getDeliveryEstimation: () => Math.floor(calculateTotalDays() * config.deliveryEstimateMultiplier),
};
